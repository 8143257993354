<template>
  <div id="app">
    <div class="container h-100">
      <div id="bar " class="d-flex justify-content-between">
        <h1>Page Builder</h1>

        <div class="d-flex">
          <button
            class="w-100 btn btn-primary btn--export-filter poppins text-no-wrap font-size-lg line-height-xl ls1 text-uppercase mr-2"
            v-on:click="saveDesign"
          >
            Save Design
          </button>
          <button
            class="w-100 btn btn-primary btn--export-filter poppins text-no-wrap font-size-lg line-height-xl ls1 text-uppercase"
            v-on:click="exportHtml"
          >
            Export HTML
          </button>
        </div>
      </div>

      <EmailEditor
        ref="emailEditor"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
        class="h-100"
      />
    </div>
  </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import sample from "@/own/templates/sample.json";
export default {
  name: "example",
  components: {
    EmailEditor,
  },
  methods: {
    // called when the editor is created
    editorLoaded() {
      // console.log("editorLoaded");
      this.$refs.emailEditor.editor.loadDesign(sample);
    },
    // called when the editor has finished loading
    editorReady() {
      // console.log("editorReady");
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        return design;
        // console.log("saveDesign", design);
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        return data;

        // console.log("exportHtml", data);
      });
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
#app,
#example {
  height: 100%;
}
#example .container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
</style>
